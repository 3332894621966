<template>
  <div class="default lecture-introduce">
    <div
        class="box"
        id="imgBox"
    >
      <!-- 背景图 -->
      <img
          :src="background_img"
          alt=""
          style="max-width:420px"
          class="bg-img"
      >
      <!-- 头像昵称 -->
      <div class="profile">
        <div class="headimgurl">
          <img
              :src="headimgurl"
              alt=""
          >
        </div>
        <div class="nickname">{{ nickname }}
        </div>
      </div>

      <!-- 主标题 -->
      <div
          :class="{'title-box':flag}"
          v-html="title"
      ></div>

      <!-- 副标题 -->
      <div
          :class="{'subhead-box':flag}"
          v-html="subTitle"
      ></div>

      <!-- 文案内容上 -->
      <div :class="{'content-box-top':flag}">
        <div class="tag">课程要点</div>
        <div
            v-for="(item,index) in contentList_top"
            :key="index"
            :class="['content-item',`content-item${index}`]"
        >
          <div :class="['content-item-icon']">
            <ul>
              <li></li>
            </ul>
          </div>
          {{item.text}}
        </div>

        <!-- <div>
          <ul>
            <li 
              v-for="(item,index) in contentList_top"
              :key="index" 
              :class="['content-item',`content-item${index}`]">
              <span>{{item.text}}</span>
            </li>
          </ul>
        </div> -->
        

      </div>

      <!-- 文案内容下 -->
      <div :class="{'content-box-bottom':flag}">
        <div class="tag">讲师介绍</div>
        <div class="content">
          <div class="img-box">
            <img
                :src="teacher_introduce.img"
                alt=""
            >
          </div>
          <div class="content-text">
            <div
                v-for="(item,index) in teacher_introduce.introduce"
                :key="index"
            >
              <span
                  :class="['teacher-name',`teacher-name${index}` ]"
                  v-if="item.name"
                  v-html="item.name"
              ></span>
              <span
                  :class="['teacher-introduce',`teacher-introduce${index}` ]"
                  v-html="item.text"
              ></span>
            </div>
          </div>
        </div>
      </div>

      <!-- 底部说明左 -->
      <div :class="{'footer-box-left':flag}">
        <div
            v-for="(item,index) in footerList_"
            :key="index"
            class="footerlist-item"
            v-html="item.text"
        >
        </div>
      </div>
      <!-- 底部说明右 -->
      <div :class="{'footer-box-right':flag}" v-if="!!qrcode_url">
        <VueQr
            :text="qrcode_url"
            :size="75"
            :margin="2"
        ></VueQr>
      </div>

    </div>
    <loading :loading="loading"></loading>
  </div>
</template>

<script>
import domtoimage from '../utils/domtoimage'
import axios from 'axios'
import VueQr from 'vue-qr'
import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      loading: false,
      background_img: '',
      flag: true,
      headimgurl: '',
      nickname: '',
      qrcode_url: '',
      title: '',
      subTitle: '',
      contentList_top: [],
      teacher_introduce: {
        img: '',
        introduce: []
      },
      footerList_: [],
    }
  },
  components: {
    VueQr
  },
  created() {
    this.getData()
  },
  mounted() {
    window.vue = this
  },
  methods: {
    //获取数据
    getData() {
      this.loading = true
      // axios.get(`${location.origin}/backend/config/${this.$route.query.config}`)
      axios.get(`https://dev.design.ceba.ceshiren.com/backend/config/${this.$route.query.config}`)
          .then(res => {
            console.log(res);
            if (res.data.data) {
              this.background_img = res.data.data.background_img
              this.title = res.data.data.title
              this.subTitle = res.data.data.subTitle
              this.qrcode_url = res.data.data.qrcode_url
              this.headimgurl = res.data.data.headimgurl
              this.nickname = res.data.data.nickname
              this.contentList_top = res.data.data.contentList_top
              this.teacher_introduce = res.data.data.teacher_introduce
              this.footerList_ = res.data.data.footerList_
              if (res.data.data.css) {
                this.createCssLink(res.data.data.css)
              } else {
                this.createCssLink('default')
              }
            }

          })
          .catch(err => {
            this.loading = false
            console.log(err);
          })
    },
    filter(node) {
      return (node.tagName !== 'i');
    },
    domtoimg() {
      console.log('domtoimg');

      var node = document.getElementById('imgBox');
      console.log(node.offsetHeight);
      console.log(node.offsetWidth);
      let width = node.offsetWidth
      let height = node.offsetHeight
      var that = this
      // ios
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log('ios');
        // domtoimage.toSvg(node, { scale: 4, width, height })
        //   .then(function (dataUrl) {
        //     var img = new Image();
        //     img.src = dataUrl;
        //     img.style.cssText =
        //       "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        //     img.id = "poster-Img-new"
        //     document.body.appendChild(img);
        //     console.log('截图完成');
        //     that.loading = false
        //   })
        //   .catch(function (error) {
        //     console.error('oops, something went wrong!', error);
        //   })
        this.saveHtml2Image()
      }
      // 安卓 || pc = domtoimage
      else {
        console.log('andriod或pc');
        domtoimage.toPng(node, {scale: 4, width, height})
            .then(function (dataUrl) {
              var img = new Image();
              img.src = dataUrl;
              img.style.cssText =
                  "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
              img.id = "poster-Img-new"
              document.body.appendChild(img);
              console.log('截图完成');
              that.loading = false
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            })
      }

    },

    //将html结构转换为图片
    saveHtml2Image() {

      //转换图片时 将高度归0 避免出现顶部白边
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;

      html2canvas(document.querySelector('#imgBox'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        let src = canvas.toDataURL("image/jpeg", 0.9)
        img.src = src
        document.body.appendChild(img);
        img.style.cssText =
            "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:9;";
        img.id = "poster-Img-new"
        this.loading = false
        console.log('html2cavas');
      });
    },

    //避免重复载入 
    removeRemoteCss(href) {
      const linkList = document.querySelectorAll('#remote_css')
      console.log(linkList);
      for (var i = 0; i < linkList.length; i++) {
        linkList[i].parentNode.removeChild(linkList[i]);
      }
    },

    // 创建link标签引用远程css
    createCssLink(css_name) {
      this.removeRemoteCss()
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.id = 'remote_css'
      const cssPath = `https://dev.design.ceba.ceshiren.com/backend/css/${css_name}.css`
      link.href = cssPath
      document.head.appendChild(link)
      console.log('css加载完成');
      setTimeout(() => {
        this.domtoimg()
      }, 1500)
    },


    //图片加载监控
    imgload_() {
      let imgList = document.getElementsByTagName('img');//图片集合
      let imgCount = imgList.length;//图片总数
      let imgLoad = 0;//加载完成的图片数量

      for (let i = 0; i < imgCount; i++) {
        imgList[i].onload = () => {
          imgLoad++;
          console.log(imgLoad);
          if (imgLoad === imgCount) {
            // img加载完成 do something
            console.log('图片全部加载完成');
            // this.saveHtml2Image()
          }
        }
      }
    },
  }
}
</script>

<style scoped lang="less">
// .lecture-introduce {
//   display: flex;
//   justify-content: center;
// }
// .box {
//   position: relative;
//   display: inline-block;
//   line-height: 0;
// }
// /* 背景图 */
// .bg-img {
//   width: 100%;
//   object-fit: contain; /* 图片尺寸自适应 */
// }
// /* 头像昵称 */
// .profile {
//   display: flex;
//   justify-content: flex-start; /* 水平居中 */
//   align-items: center; /* 垂直居中 */
//   position: absolute;
//   top: 2%;
//   left: 5%;
// }
// /* 头像 */
// .headimgurl {
//   width: 50px;
//   height: 50px;
//   border-radius: 50%; /* 圆角属性 */
//   overflow: hidden; /* 溢出隐藏 */
//   background-color: #fff; /* 背景色 */
// }
// /* 昵称 */
// .nickname {
//   color: #fff; /* 文字颜色 */
//   font-size: 16px; /* 字体大小 */
//   margin-left: 10px; /* 左边距 */
// }

// .headimgurl img {
//   width: 100%;
// }

// .title-box,
// .content-box-bottom,
// .footer-box-left,
// .footer-box-right,
// .content-box-top,
// .subhead-box {
//   position: absolute;
//   line-height: 1;
// }
// /* 主标题 */
// .title-box {
//   width: 100%;
//   text-align: center; /* 文字水平居中 */
//   color: #fafbec; /* 文字颜色 */
//   padding: 0 20px;
//   font-weight: 700; /* 字体加粗 */
//   top: 11%;
//   text-align: start;
//   font-size: 30px; /* 字体大小 */
//   color: #fff;
//   line-height: 1.2; /* 行高 */
//   letter-spacing: 3px; /* 字体左右间距 */
// }
// /* 副标题 */
// .subhead-box {
//   width: 100%;
//   padding: 0 20px;
//   top: 23%;
//   text-align: start; /* 文字居左对齐 */
//   font-size: 16px;
//   color: #fff;
//   line-height: 1;
// }
// /*课程要点 小标题  */
// .tag {
//   position: absolute;
//   top: -12px;
//   width: 100px;
//   height: 30px;
//   background-color: #dba052;
//   font-size: 17px;
//   font-weight: 700;
//   text-align: center;
//   line-height: 30px;
//   color: #fff;
//   border-top-left-radius: 25px; /* 上左圆角属性 */
//   border-top-right-radius: 25px; /* 上右圆角属性 */
//   border-bottom-right-radius: 25px; /* 下右圆角属性 */
// }
// /* 课程要点内容 */
// .content-box-top {
//   top: 29.5%;
//   width: 100%;
//   letter-spacing: 1px;
//   font-size: 16px;
//   font-family: "Microsoft YaHei";
//   padding: 5px 15px;
// }
// /* 课程要点内容 每一行icon图片 */
// .content-item-icon {
//   width: 40px;
//   height: 100%;
//   /* background-color: red; */
//   display: flex;
//   align-items: center;
// }
// /* 课程要点内容 每一行 */
// .content-item {
//   display: flex;
//   align-items: center;
//   border-bottom: 1px dotted #000;
//   height: 36px;
//   font-size: 16px;
//   background-color: #fff;
//   color: #000;
// }
// /* 课程要点内容 第一行 */
// .content-box-top .content-item0 {
//   height: 50px;
//   padding-top: 10px; /* 上边距 */
// }

// .content-box-top > div:last-child {
//   overflow: hidden;
//   border-bottom-left-radius: 9px;
//   border-bottom-right-radius: 9px;
// }
// .content-box-top > div:nth-child(2) {
//   overflow: hidden;
//   border-top-right-radius: 9px;
// }

// /* 讲师介绍 */
// .content-box-bottom {
//   top: 60%;
//   width: 100%;
//   padding: 5px 15px;
//   letter-spacing: 1px;
//   font-size: 16px;
//   font-family: "Microsoft YaHei";
//   /* height: 151px; */
// }
// /* 讲师介绍 主体内容盒子 左边图片 右边描述 */
// .content-box-bottom .content {
//   padding-top: 15px;
//   height: 150px;
//   background-color: #fff;
//   border-top-right-radius: 9px;
//   border-bottom-left-radius: 9px;
//   border-bottom-right-radius: 9px;
//   display: flex;
//   align-items: center;
// }
// /*主体内容盒子 左边图片 */
// .content .img-box {
//   width: 90px;
//   height: 100px;
//   overflow: hidden;
// }
// /*主体内容盒子 左边图片 */
// .content .img-box img {
//   height: 100%;
// }
// /*主体内容盒子 右边每一行 */
// .content .content-text {
//   margin-left: 12px;
// }
// /* 讲师name */
// .teacher-name {
//   font-size: 18px;
//   color: #2f5eb5;
// }
// /* 第一个讲师name */
// .teacher-name0 {
//   font-weight: 700;
// }
// /* 讲师描述介绍 每一行的文字大小 */
// .teacher-introduce {
//   font-size: 12px;
// }
// /* 讲师描述介绍 第一行的文字大小 */
// .teacher-introduce0 {
//   font-weight: 700;
//   font-size: 14px;
// }
// /* 二维码 */
// .footer-box-right {
//   line-height: 0;
//   top: 87%;
//   left: 74%;
// }
// /* 最底部文字 */
// .footer-box-left {
//   width: 67%;
//   top: 87%;
//   left: 8%;
// }
// /* 最底部文字 每一行 */
// .footerlist-item {
//   display: flex;
//   align-items: center;
//   height: 35px;
//   font-size: 20px;
//   background: transparent;
//   color: #fff;
// }
</style>